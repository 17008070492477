/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ForgotPasswordSteps from '../../pages/ForgotPasswordSteps/ForgotPasswordSteps'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className=''
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/myImages/background.jpg')})`,
        backgroundSize: 'cover',
      }}
    >
      <div className='py-[21dvh] px-[5%]'>
        <div className='w-[320px] md:w-[500px] bg-body rounded shadow-sm p-5 mx-auto border-hover'>
          <Outlet />
        </div>
      </div>
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a
            href='https://test-resource-public.arigbank.mn/storage/document/merchant/cos_alert.pdf'
            className='text-secondary text-hover-primary px-2'
            target='_blank'
            rel='noreferrer'
          >
            КҮБ-ийн санамж
          </a>

          <a
            href='https://test-resource-public.arigbank.mn/storage/document/merchant/holboo.jpg'
            className='text-secondary text-hover-primary px-2'
            target='_blank'
            rel='noreferrer'
          >
            Холбоо барих
          </a>
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPasswordSteps />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
