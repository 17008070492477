import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import axiosWeb from "../../../axiosWeb";
import {authHeader} from "../../modules/auth/core/_requests";


interface Step3Props {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    loginName: React.Dispatch<React.SetStateAction<string>>;
    email: React.Dispatch<React.SetStateAction<string>>;
    setOtpCode: React.Dispatch<React.SetStateAction<string>>;
    hashedMail: React.Dispatch<React.SetStateAction<string>>;
}

const initialValues = {
    verificationCode: '',
}

const step3Schema = Yup.object().shape({
    verificationCode: Yup.number()
        .typeError('Зөвхөн тоо оруулна уу!.')
        .required('Баталгаажуулах код оруулна уу.')
        .test('is-six-digits', 'Баталгаажуулах код 6 тэмдэгттэй байна.', (value) => {
            if (value) {
                const codeAsString = String(value);
                return codeAsString.length === 6;
            }
            return true;
        }),
})

function Step3({setStep, loginName, email, setOtpCode, hashedMail}: Step3Props) {
    const authorizationHeader = authHeader();
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [countdown, setCountdown] = useState(900)


    const confirmCodeResend = () => {
        setCountdown(900);
        axiosWeb
            .post("/restapi/email/sendGenerateToken", {
                username: loginName,
                email: email,
                hashedMail: hashedMail
            }, {
                headers: {
                    // @ts-ignore
                    'Authorization': authorizationHeader,
                }
            })
            .then((res) => {
                console.log(res)
                if (res.data.status === 200) {
                    setSuccessMsg(res.data.message);
                    setHasErrors(false);
                } else {
                    setHasErrors(true);
                    setErrorMsg(res.data.message);
                }
                setLoading(false);
            });

        setTimeout(() => {
            setHasErrors(undefined);
        }, 3000);
    };


    useEffect(() => {
        const timer = setInterval(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            } else {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [countdown]);

    const formik = useFormik({
        initialValues,
        validationSchema: step3Schema,
        onSubmit: (values, {setSubmitting}) => {
            setLoading(true);
            setHasErrors(undefined);
            setTimeout(() => {
                handleNext(values.verificationCode);
                setSubmitting(false);
            }, 1000);
        },
    });

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                formik.handleSubmit();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [formik]);

    const handlePrev = () => {
        setStep(2);
    };

    const handleNext = (verificationCode: string) => {
        if (verificationCode) {
            axiosWeb
                .post("/restapi/email/checkOpt", {
                    username: loginName,
                    email: email,
                    checkOptCode: verificationCode
                }, {
                    headers: {
                        // @ts-ignore
                        'Authorization': authorizationHeader,
                    }
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        setHasErrors(false)
                        setSuccessMsg(res.data.message)
                        setOtpCode(verificationCode)
                        setStep(4);
                    } else {
                        setHasErrors(true)
                        setErrorMsg(res.data.message)
                    }
                    setLoading(false)

                });
        } else {
            setStep(3);
            setHasErrors(true)
            setLoading(false)
        }
    };

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark fw-bolder mb-3'>Нууц үг мартсан</h1>
            </div>

            {hasErrors === true && (
                <div className='mb-10 bg-light-danger rounded'>
                    <div className='alert alert-danger'>{errorMsg}</div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-info rounded'>
                    <div className='alert alert-success'>{successMsg}</div>
                </div>
            )}

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6 required'>Баталгаажуулах код</label>
                <div className='input-group'>
                    <input
                        type='number'
                        placeholder='Баталгаажуулах код'
                        autoComplete='off'
                        {...formik.getFieldProps('verificationCode')}
                        className={clsx(
                            'form-control bg-transparent',
                            {'is-invalid': formik.touched.verificationCode && formik.errors.verificationCode},
                            {
                                'is-valid': formik.touched.verificationCode && !formik.errors.verificationCode,
                            }
                        )}
                    />
                    <button
                        className="btn btn-info"
                        style={{cursor: 'pointer'}}
                        onClick={confirmCodeResend}
                        disabled={countdown > 0}
                    >
                        <i className='bi bi-repeat'></i> Код дахин авах
                    </button>
                </div>
                {formik.touched.verificationCode && formik.errors.verificationCode && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.verificationCode}</span>
                        </div>
                    </div>
                )}
            </div>
            {countdown > 0 ? (<div className='mb-10 bg-light-info rounded text-center'>
                <p className='text-info'>Код дахин авах хугацаа</p>
                <h2 className='text-info'>
                    {Math.floor(countdown / 60).toString().padStart(2, '0')} : {(countdown % 60).toString().padStart(2, '0')}
                </h2>
            </div>) : null}

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-info me-4'
                        disabled={formik.isSubmitting || !formik.isValid}>
                    {!loading && <span className='indicator-label'>Шалгах</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Түр хүлээнэ үү....
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>
                <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-light'
                    onClick={handlePrev}
                >
                    Буцах
                </button>
            </div>
        </form>
    )
}

export {Step3}