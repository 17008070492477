import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {MerchantList} from '../pages/merchant/MerchantList'
import {ResetPassword} from "../pages/merchant/ResetPassword";
const PrivateRoutes = () => {

    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                <Route path='auth/*' element={<Navigate to='/list'/>}/>

                <Route path='list' element={<MerchantList/>}/>
                <Route path='/account/settings' element={<ResetPassword/>}/>
            </Route>
        </Routes>
    )
}

export {PrivateRoutes}
