import {AuthModel, UserModel} from './_models'
import axiosWeb from "../../../../axiosWeb";

export function authHeader() {
    const authUsername = 'sub_merchant';
    const authPassword = 'pass123456';
    return 'Basic ' + btoa(authUsername + ':' + authPassword);
}


export async function getUserByToken(token: string | AuthModel | undefined, username: string | undefined): Promise<UserModel | null> {
    try {
        const response = await axiosWeb.post('/restapi/user/login/verifyToken', {
            username: username,
            token: token,
        }, {
            headers: {
                // @ts-ignore
                'Authorization': authHeader(),
            }
        });

        if (response.data.status === 200) {
            return {
                userId: response.data.data.userId,
                orgName: response.data.data.orgName,
                username: response.data.data.username,
                email: response.data.data.email,
                api_token: response.data.data.api_token,
            };
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}