import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import axiosWeb from "../../../axiosWeb";
import {authHeader} from "../../modules/auth/core/_requests";


interface Step1Props {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    setLoginName: React.Dispatch<React.SetStateAction<string>>;
    setEncryptedEmail: React.Dispatch<React.SetStateAction<string>>;
    setHashedMail: React.Dispatch<React.SetStateAction<string>>;
}


const initialValues = {
    username: '',
}

const step1Schema = Yup.object().shape({
    username: Yup.string()
        .required('Нэвтрэх нэр оруулна уу!'),
})

function Step1({setStep, setLoginName, setEncryptedEmail, setHashedMail}: Step1Props) {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const formik = useFormik({
        initialValues,
        validationSchema: step1Schema,
        onSubmit: (values, {setSubmitting}) => {
            setLoading(true);
            setHasErrors(undefined);
            setTimeout(() => {
                handleNext(values.username);
                setSubmitting(false);
            }, 1000);
        },
    });

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                formik.handleSubmit();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [formik]);

    const handleNext = (username: string) => {
        const authorizationHeader = authHeader();
        if (username) {
            axiosWeb
                .post("/restapi/user/passwordReset/checkUserName", {
                    username: username,
                }, {
                    headers: {
                        // @ts-ignore
                        'Authorization': authorizationHeader,
                    }
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        setEncryptedEmail(res.data.data.maskedMail)
                        setLoginName(res.data.data.username);
                        setHashedMail(res.data.data.hashedMail);
                        setSuccessMsg(res.data.message)
                        setStep(2);
                        setHasErrors(false)
                    } else {
                        setHasErrors(true)
                        setErrorMsg(res.data.message)
                    }
                    setLoading(false)
                });
        } else {
            setStep(1);
            setHasErrors(true)
            setLoading(false)
        }
    };

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark fw-bolder mb-3'>Нууц үг мартсан</h1>
            </div>

            {hasErrors === true && (
                <div className='alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {errorMsg}
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='bg-light-info p-8 rounded'>
                    <div className='text-info'>{successMsg}</div>
                </div>
            )}

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Нэвртэх нэр</label>
                <input
                    type='text'
                    placeholder='Нэвртэх нэр'
                    autoComplete='off'
                    {...formik.getFieldProps('username')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.username && formik.errors.username},
                        {
                            'is-valid': formik.touched.username && !formik.errors.username,
                        }
                    )}
                />
                {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.username}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-info me-4'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Шалгах</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              Түр хүлээнэ үү....
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                    >
                        Буцах
                    </button>
                </Link>{' '}
            </div>
        </form>
    )
}

export {Step1}