/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon, toAbsoluteUrl} from "../../../helpers"

const HeaderUserMenu: FC = () => {
    const {currentUser, logout} = useAuth()

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/myImages/black.png')} />
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-5'>
                            {currentUser?.orgName}
                        </div>
                        <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                            {currentUser?.email}
                        </a>
                    </div>
                </div>
            </div>
            <div className='separator my-2'></div>
            <div className='menu-item px-5 my-1'>

                <Link to='/account/settings' className='menu-link px-5'>
                <span className='menu-icon' data-kt-element='icon'>
                    <KTIcon iconName='lock' className='fs-1'/>
                </span>
                    &nbsp; Нууц үг солих
                </Link>
            </div>

            <div className='menu-item px-5'>
                <a onClick={logout} className='menu-link px-5'>
                    <span className='menu-icon' data-kt-element='icon'>
                        <KTIcon iconName='bi bi-box-arrow-right' className='fs-1'/>
                    </span>
                    &nbsp; Гарах
                </a>
            </div>
            <div className='separator my-2'></div>
        </div>
    )
}

export {HeaderUserMenu}
