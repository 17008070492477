/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {addDays, format} from 'date-fns'
import axiosWeb from '../../../axiosWeb'
// @ts-ignore
import SweetAlert from 'sweetalert2'
import {authHeader} from '../../modules/auth/core/_requests'

type Props = {className: string}

interface subCos {
  subMerchantId: number
  subMerchantName: string
  subMerchantCode: string
}

interface SubMerchant {
  terminalCode: number
  subMerchantName: string
  subMerchantCode: string
}

const SearchPages: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const [subCos, setSubCos] = useState<subCos[]>([])
  const [merchants, setMerchants] = useState<SubMerchant[]>([])
  const [error, setError] = useState(false)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const minDate = new Date('2022-11-25')
  const currentDate = new Date()
  const [dateError, setDateError] = useState({
    startDateError: '',
    endDateError: '',
  })

  const {currentUser, logout} = useAuth()
  const userID = currentUser?.userId
  const token = currentUser?.api_token
  const authorizationHeader = authHeader()

  const [merchantData, setMerchantData] = useState({
    sub_cos: '',
    tailanType: 'SUMMARY',
    terminalNumber: '',
    startDate: '',
    endDate: '',
  })

  useEffect(() => {
    setError(false)
  }, [merchantData.startDate, merchantData.endDate])

  useEffect(() => {
    setMerchants([])
    try {
      axiosWeb
        .post(
          '/restapi/merchant/select/subMerchantByUserId',
          {
            userId: userID,
            token: token,
          },
          {
            headers: {
              Authorization: authorizationHeader,
            },
          }
        )
        .then((res) => {
          if (res.status === 401) {
            logout()
          } else {
            setSubCos(res.data.data)
          }
        })
        .catch((error) => {
          logout()
        })
    } catch (error) {
      logout()
    }
  }, [authorizationHeader, userID])

  useEffect(() => {
    const subCosCode = merchantData.sub_cos
    try {
      axiosWeb
        .post(
          '/restapi/merchant/select/terminalByUserId',
          {
            userId: userID,
            subMerchantCode: subCosCode,
            token: token,
          },
          {
            headers: {
              Authorization: authorizationHeader,
            },
          }
        )
        .then((res) => {
          if (res.status === 401) {
            logout()
          } else {
            setMerchants(res.data.data)
          }
        })
        .catch((error) => {
          logout()
        })
    } catch (error) {
      logout()
    }
  }, [authorizationHeader, merchantData.sub_cos, userID])

  const count = merchants?.length ? merchants.length : 0

  const handleDownload = () => {
    const authorizationHeader = authHeader()
    if (!merchantData.startDate || !merchantData.endDate) {
      setError(true)
      setLoading(false)
    } else {
      setError(false)
      setLoading(true)
      try {
        axiosWeb
          .post(
            '/restapi/merchant/report',
            {
              userId: userID,
              merchantCode: merchantData.sub_cos,
              type: merchantData.tailanType,
              terminalCode: merchantData.terminalNumber,
              startDate: merchantData.startDate,
              endDate: merchantData.endDate,
              token: token,
            },
            {
              responseType: 'arraybuffer',
              headers: {
                // @ts-ignore
                Authorization: authorizationHeader,
              },
              timeout: 0,
            }
          )
          .then((res) => {
            if (res.status === 401) {
              logout()
            } else {
              const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              })
              if (blob.size === 0) {
                setLoading(false)
                return SweetAlert.fire({
                  title: 'Тухайн хугацаанд тайлан олдсонгүй',
                  icon: 'error',
                  confirmButtonText: 'Хаах',
                })
              } else {
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = `${merchantData.startDate}-${merchantData.endDate}.xlsx`
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
                setLoading(false)
                return SweetAlert.fire({
                  title: 'Тайлан амжилттай татлаа',
                  icon: 'success',
                  confirmButtonText: 'Хаах',
                })
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            logout()
            return SweetAlert.fire({
              title: 'Алдаа гарлаа' + error,
              icon: 'error',
              confirmButtonText: 'Хаах',
            })
          })
      } catch (error) {
        logout()
      }
    }
  }

  const changeValue = (value: any, property: string) => {
    setMerchantData({...merchantData, [property]: value})
  }
  const changeError = (value: any, property: string) => {
    setDateError({...dateError, [property]: value})
  }
  const handleStartDateChange = (date: any) => {
    const endDate = merchantData.endDate
    const maxEndDate = addDays(new Date(date), 31)

    if (endDate && date > endDate) {
      changeError('Эхлэх огноо Дуусах огнооноос хойш байх боломжгүй', 'startDateError')
    } else if (date > currentDate) {
      changeError('Эхлэх огноо нь өнөөдрөөс хойш байх боломжгүй', 'startDateError')
    } else if (endDate && maxEndDate < new Date(endDate)) {
      changeError('31 -ээс дээш хоногт тайлан авах боломжгүй', 'startDateError')
    } else {
      const formattedStartDate = date ? format(new Date(date), 'yyyy-MM-dd') : null
      changeError('', 'startDateError')
      changeValue(formattedStartDate, 'startDate')
      // @ts-ignore
      setSelectedEndDate(maxEndDate)
    }
  }

  const handleEndDateChange = (date: any) => {
    const startDate = merchantData.startDate
    const maxEndDate = selectedEndDate || currentDate

    if (startDate && date < startDate) {
      changeError('Дуусах огноо Эхлэх огнооноос урагш байх боломжгүй', 'endDateError')
    } else if (date > maxEndDate) {
      changeError('31 -ээс дээш хоногт тайлан авах боломжгүй', 'endDateError')
    } else {
      const formattedEndDate = date ? format(new Date(date), 'yyyy-MM-dd') : null
      changeError('', 'endDateError')
      changeValue(formattedEndDate, 'endDate')
    }
  }

  return (
    <div className={`card ${className}`} style={{borderRadius: '5px'}}>
      <div className='pt-2 card-body row'></div>
      <div className='grid w-full grid-cols-1 gap-4 pt-2 card-body md:grid-cols-2 md:gap-0'>
        <div className='items-center md:flex'>
          <label className='w-[180px]'>КҮБ</label>
          <div>
            <input
              className='form-control'
              type='text'
              value={currentUser?.orgName}
              id='example-text-input'
              disabled
            />
          </div>
        </div>

        <div className='items-center md:flex'>
          <label className='w-[180px]'>Хайлтын нөхцөл</label>
          <div>
            <input
              className='form-control '
              type='text'
              value=' Дансанд орсон огноо'
              id='example-text-input'
              disabled
            />
          </div>
        </div>
      </div>
      <div className='grid w-full grid-cols-1 gap-4 pt-2 card-body md:grid-cols-2 md:gap-0'>
        <div className='items-center md:flex'>
          <label className='w-[180px]'>Дэд КҮБ</label>
          <div>
            <select
              className='form-select'
              disabled={loading}
              onChange={(e) => changeValue(e.target.value, 'sub_cos')}
            >
              <option value=''> Бүгд</option>
              {subCos.map((item, index) => (
                <option key={index + 1} value={item.subMerchantCode}>
                  {item.subMerchantName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='items-center md:flex'>
          <label className='w-[180px]'>Тайлангийн төрөл</label>
          <div className='md:w-[225px]'>
            <select
              className='form-select selector'
              disabled={loading}
              onChange={(e) => changeValue(e.target.value, 'tailanType')}
            >
              <option value='SUMMARY'>Хураангуй</option>
              <option value='DETAIL'>Дэлгэрэнгүй</option>
            </select>
          </div>
        </div>
      </div>
      <div className='grid w-full grid-cols-1 gap-4 pt-2 card-body md:grid-cols-2 md:gap-0'>
        <div className='items-center md:flex'>
          <label className='w-[180px]'>Терминал дугаар</label>
          {merchantData.tailanType === 'SUMMARY' || merchantData.sub_cos === '' ? (
            <div>
              {merchants.length === 1 ? (
                merchants.map((item, index) => (
                  <div key={item.subMerchantCode || index}>
                    <input
                      className='form-control'
                      type='text'
                      value={item.subMerchantCode}
                      id='example-text-input'
                      disabled
                    />
                  </div>
                ))
              ) : (
                <div key='all-merchants'>
                  <input
                    className='form-control'
                    type='text'
                    value='Бүгд'
                    id='example-text-input'
                    disabled
                  />
                </div>
              )}
            </div>
          ) : (
            <>
              {merchants.length === 1 ? (
                merchants.map((item, index) => (
                  <div key={item.subMerchantCode || index}>
                    <input
                      className='form-control'
                      type='text'
                      value={item.subMerchantCode}
                      id='example-text-input'
                      disabled
                    />
                  </div>
                ))
              ) : (
                <div>
                  <select
                    className='form-select'
                    required
                    disabled={loading}
                    onChange={(e) => changeValue(e.target.value, 'terminalNumber')}
                  >
                    <option value=''> Бүгд</option>
                    {merchants.map((item, index) => (
                      <option key={index + 1} value={item.terminalCode}>
                        {item.subMerchantName} - {item.terminalCode}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </>
          )}
        </div>
        <div className='items-center md:flex'>
          <label className='w-[180px]'>Эхлэх огноо</label>
          <div>
            <DatePicker
              className={`form-control w-[75vw] md:w-full ${error ? 'border-red-500' : ''}`}
              disabled={loading}
              selected={merchantData.startDate ? new Date(merchantData.startDate) : null}
              minDate={minDate}
              onChange={handleStartDateChange}
              placeholderText='YYYY-MM-DD'
              dateFormat='yyyy-MM-dd'
            />
            {dateError.startDateError ? (
              <div className='col-7 alert alert-danger'>{dateError.startDateError}</div>
            ) : null}
            {error ? <div className='pt-2 text-red-500'>Огноо оруулна уу!</div> : null}
          </div>
        </div>
      </div>
      <div className='grid w-full grid-cols-1 gap-4 pt-2 card-body md:grid-cols-2 md:gap-0'>
        <div className='items-center md:flex'>
          <label className='w-[180px]'>Терминал тоо</label>
          <div>
            <input
              className='form-control'
              type='text'
              value={count}
              id='example-text-input'
              disabled
            />
          </div>
        </div>
        <div className='items-center md:flex'>
          <label className='w-[180px]'>Дуусах огноо</label>
          <div>
            <DatePicker
              className={`form-control w-[75vw] md:w-full ${error ? 'border-red-500' : ''}`}
              disabled={loading}
              selected={merchantData.endDate ? new Date(merchantData.endDate) : null}
              minDate={minDate}
              onChange={handleEndDateChange}
              placeholderText='YYYY-MM-DD'
              dateFormat='yyyy-MM-dd'
              isClearable={false}
            />
            {dateError.endDateError ? (
              <div className='col-7 alert alert-danger'>{dateError.endDateError}</div>
            ) : null}
            {error ? <div className='pt-2 text-red-500'>Огноо оруулна уу!</div> : null}
          </div>
        </div>
      </div>
      <div className='pt-2 card-body row'>
        <div className='flex md:w-[77%] justify-end'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            onClick={handleDownload}
            className='btn btn-primary'
            disabled={loading || error}
          >
            {!loading && <span className='indicator-label'>Татах</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Түр хүлээнэ үү...
                <span className='align-middle spinner-border spinner-border-sm ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export {SearchPages}
