/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {authHeader, getUserByToken} from '../core/_requests'
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha'
import {useAuth} from '../core/Auth'
import axiosWeb from '../../../../axiosWeb'
import {AuthModel} from '../core/_models'

declare global {
  interface Window {
    grecaptcha: ReCAPTCHA
  }
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [loginData, setLoginData] = useState({token: '', password: '', username: ''})
  const [passwordSee, setPasswordSee] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [successMsg, setSuccessMsg] = useState('')

  const changeValue = (value: string, property: string) => {
    setHasErrors(undefined)
    // @ts-ignore
    formik.setFieldValue([property], value)
    setLoginData({...loginData, [property]: value})
  }

  const toggleChangePasswordVis = () => {
    setPasswordSee((prevShowPassword) => !prevShowPassword)
  }

  //   const RECAPTCHA_SITE_KEY = process.env.PROD_KEY
  const loginSchema = Yup.object().shape({
    username: Yup.string().required('Нэвтрэх нэр оруулна уу!'),
    password: Yup.string().required('Нууц үг оруулна уу!'),
    token: Yup.string().required('Captcha бөглөнө үү!'),
  })

  const initialValues = {
    username: '',
    password: '',
    token: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      handleLogin(values.username, values.password, values.token)
    },
  })

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        formik.handleSubmit()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [formik])

  const handleLogin = (username: string, password: string, token: string) => {
    const authorizationHeader = authHeader()
    try {
      axiosWeb
        .post(
          '/restapi/user/login',
          {
            username: username,
            password: password,
            captcha: token,
          },
          {
            headers: {
              // @ts-ignore
              Authorization: authorizationHeader,
            },
          }
        )
        .then(async (res) => {
          if (res.data.status === 200) {
            // @ts-ignore
            const user = await getUserByToken(res.data.data.api_token, username)

            if (user === null) {
              setLoading(false)
              setHasErrors(true)
              setErrorMsg('L90 - Хэрэглэгчийн мэдээлэл олдсонгүй')
            }
            // @ts-ignore
            setCurrentUser(user)
            const authSet: AuthModel = {
              // @ts-ignore
              api_token: user?.api_token,
              // @ts-ignore
              username: user?.username,
            }

            saveAuth(authSet)
            setSuccessMsg(res.data.message)
            setLoading(false)
            formik.resetForm()
          } else {
            window.grecaptcha.reset()
            formik.resetForm()
            setHasErrors(true)
            setErrorMsg(res.data.message)
            setLoading(false)
          }
        })
    } catch (error) {
      window.grecaptcha.reset()
      formik.resetForm()
      setErrorMsg('L100 - Баазтай холбогдоход алдаа гарлаа')
      setHasErrors(true)
      setLoading(false)
    }
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='mb-3 text-dark fw-bolder'>Нэвтрэх хэсэг</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Мерчантын хуулга авах систем</div>
      </div>

      {hasErrors === true && (
        <div className='alert alert-danger'>
          <div className='text-center alert-text font-weight-bold'>{errorMsg}</div>
        </div>
      )}

      {hasErrors === false && (
        <div className='alert alert-success'>
          <div className='text-center alert-text font-weight-bold'>{successMsg}</div>
        </div>
      )}

      <div className='mb-8 fv-row'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Нэвтрэх нэр</label>
        <input
          {...formik.getFieldProps('username')}
          placeholder='Нэвтрэх нэр'
          autoFocus
          onChange={(e) => changeValue(e.target.value, 'username')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='text'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>

      <div className='mb-3 fv-row'>
        <label className='mb-0 form-label fw-bolder text-dark fs-6 required'>Нууц үг</label>
        <div className='input-group'>
          <input
            {...formik.getFieldProps('password')}
            type={passwordSee ? 'text' : 'password'}
            placeholder='Нууц үг'
            autoComplete='off'
            onChange={(e) => changeValue(e.target.value, 'password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <span
            className='input-group-text'
            style={{cursor: 'pointer'}}
            onClick={toggleChangePasswordVis}
          >
            {passwordSee ? (
              <i className='bi bi-eye-fill'></i>
            ) : (
              <i className='bi bi-eye-slash-fill'></i>
            )}
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='flex-wrap gap-3 mb-8 d-flex flex-stack fs-base fw-semibold'>
        <div />

        <Link to='/auth/forgot-password' className='link-primary'>
          Нууц үг мартсан?
        </Link>
      </div>

      <div className='flex flex-col w-full pb-5'>
        <ReCAPTCHA
          {...formik.getFieldProps('token')}
          sitekey='6LdIFDEpAAAAAJayg1CtTFWRY2wrOXEdWdoCT0sG'
          onChange={(e: any) => changeValue(e, 'token')}
        />
        {formik.errors.token && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.token}</span>
            </div>
          </div>
        )}
      </div>

      <div className='mb-10 d-grid'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Нэвтрэх</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Түр хүлээнэ үү....
              <span className='align-middle spinner-border spinner-border-sm ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
