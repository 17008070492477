import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
// @ts-ignore
import {useNavigate} from 'react-router-dom';
import clsx from 'clsx'
import {useFormik} from 'formik'
import axiosWeb from "../../../axiosWeb";
import {authHeader} from "../../modules/auth/core/_requests";


interface Step3Props {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    loginName: React.Dispatch<React.SetStateAction<string>>;
    email: React.Dispatch<React.SetStateAction<string>>;
    hashedMail: React.Dispatch<React.SetStateAction<string>>;
    encryptedEmail: React.Dispatch<React.SetStateAction<string>>;
    otpCode: React.Dispatch<React.SetStateAction<string>>;
}

const initialValues = {
    newPassword: '',
    confirmPassword: '',
}

const step4Schema = Yup.object().shape({
    newPassword: Yup.string()
        .matches(/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]*$/, 'Зөвхөн Англи үсэг, тоо, тусгай тэмдэгт оруулна уу!!')
        .required('Нууц үг оруулна уу!!'),
    confirmPassword: Yup.string()
        .matches(/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]*$/, 'Зөвхөн Англи үсэг, тоо, тусгай тэмдэгт оруулна уу!!')
        .required('Please confirm the password')
        .oneOf([Yup.ref('newPassword')], 'Нууц үг тохирохгүй байна!!'),
});

function Step4({loginName, email, hashedMail, encryptedEmail, setStep, otpCode}: Step3Props) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [newPassword, setNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);

    const toggleNewPasswordVis = () => {
        setNewPassword((prevShowPassword) => !prevShowPassword);
    };
    const toggleChangePasswordVis = () => {
        setConfirmPassword((prevShowPassword) => !prevShowPassword);
    };


    const formik = useFormik({
        initialValues,
        validationSchema: step4Schema,
        onSubmit: (values, {setSubmitting}) => {
            setLoading(true);
            setHasErrors(undefined);
            setTimeout(() => {
                handleNext(values.newPassword);
                setSubmitting(false);
            }, 1000);
        },
    });

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                formik.handleSubmit();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [formik]);

    const handlePrev = () => {
        setStep(3);
    };

    const handleNext = (newPassword: string) => {
        const authorizationHeader = authHeader();
        if (newPassword) {
            axiosWeb
                .post("/restapi/user/passwordReset/passwordUpdate", {
                    username: loginName,
                    newPassword: newPassword,
                    email: email,
                    maskedMail: encryptedEmail,
                    hashedMail: hashedMail,
                    opt: otpCode,
                }, {
                    headers: {
                        // @ts-ignore
                        'Authorization': authorizationHeader,
                    }
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        setHasErrors(false)
                        setSuccessMsg(res.data.message)
                        setTimeout(() => {
                            navigate('/auth/login');
                        }, 1000);
                    } else {
                        setHasErrors(true)
                        setErrorMsg(res.data.message)
                    }
                    setLoading(false)
                });

        } else {
            setHasErrors(true)
            setLoading(false)
        }
    };

    return (

        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark fw-bolder mb-3'>Нууц үг мартсан</h1>
            </div>

            {hasErrors === true && (
                <div className='alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {errorMsg}
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='bg-light-info p-8 rounded'>
                    <div className='text-info'>{successMsg}</div>
                </div>
            )}

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6 required'>Шинэ нууц үг</label>
                <div className='input-group'>
                    <input
                        type={newPassword ? 'text' : 'password'}
                        placeholder='Нууц үг оруулна уу'
                        autoComplete='off'
                        {...formik.getFieldProps('newPassword')}
                        className={clsx(
                            'form-control bg-transparent',
                            {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                            {
                                'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                            }
                        )}
                    />
                    <span
                        className='input-group-text'
                        style={{cursor: 'pointer'}}
                        onClick={toggleNewPasswordVis}
                    >
                      {newPassword ? (
                          <i className='bi bi-eye-fill'></i>
                      ) : (
                          <i className='bi bi-eye-slash-fill'></i>
                      )}
                    </span>
                </div>
                {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.newPassword}</span>
                        </div>
                    </div>
                ) : (<div className='text-muted'>
                    Том үсэг, Жижиг үсэг, Тоо, Тусгай тэмдэгт байна.
                </div>)}

            </div>
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6 required'>Нууц үг давтах</label>
                <div className='input-group'>
                    <input
                        type={confirmPassword ? 'text' : 'password'}
                        placeholder='Нууц үг давтах'
                        autoComplete='off'
                        {...formik.getFieldProps('confirmPassword')}
                        className={clsx(
                            'form-control bg-transparent',
                            {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                            {
                                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                            }
                        )}
                    />
                    <span
                        className='input-group-text'
                        style={{cursor: 'pointer'}}
                        onClick={toggleChangePasswordVis}
                    >
                      {confirmPassword ? (
                          <i className='bi bi-eye-fill'></i>
                      ) : (
                          <i className='bi bi-eye-slash-fill'></i>
                      )}
                    </span>
                </div>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-info me-4'
                        disabled={formik.isSubmitting || !formik.isValid}>
                    {!loading && <span className='indicator-label'>Хадгалах</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              Түр хүлээнэ үү....
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>
                <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-light'
                    onClick={handlePrev}
                >
                    Буцах
                </button>
            </div>
        </form>
    )
}

export {Step4}