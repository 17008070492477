import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import axiosWeb from "../../../axiosWeb";
import {authHeader} from "../../modules/auth/core/_requests";


interface Step2Props {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    loginName: React.Dispatch<React.SetStateAction<string>>;
    encryptedEmail: React.Dispatch<React.SetStateAction<string>>;
    hashedMail: React.Dispatch<React.SetStateAction<string>>;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const initialValues = {
    email: '',
}

const step2Schema = Yup.object().shape({
    email: Yup.string()
        .email('Формат таарахгүй байна')
        .required('Имэйл оруулна уу!'),
})

function Step2({setStep, loginName, encryptedEmail, hashedMail, setEmail}: Step2Props) {

    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const formik = useFormik({
        initialValues,
        validationSchema: step2Schema,
        onSubmit: (values, {setSubmitting}) => {
            setLoading(true);
            setTimeout(() => {
                handleNext(values.email);
                setSubmitting(false);
            }, 1000);
        },
    });


    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                formik.handleSubmit();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [formik]);

    const handlePrev = () => {
        setStep(1);
    };

    const handleNext = (email: string) => {
        const authorizationHeader = authHeader();
        if (email) {
            axiosWeb
                .post("/restapi/user/passwordReset/checkEmail", {
                    username: loginName,
                    email: email,
                    hashedMail: hashedMail
                }, {
                    headers: {
                        // @ts-ignore
                        'Authorization': authorizationHeader,
                    }
                })
                .then((res) => {
                    if (res.data.status === 200) {

                        axiosWeb
                            .post("/restapi/email/sendGenerateToken", {
                                username: loginName,
                                email: email,
                                hashedMail: hashedMail
                            }, {
                                headers: {
                                    // @ts-ignore
                                    'Authorization': authorizationHeader,
                                }
                            })
                            .then((res) => {
                                if (res.data.status === 200) {
                                    setEmail(email);
                                    setSuccessMsg(res.data.message);
                                    setStep(3);
                                    setHasErrors(false);
                                } else {
                                    setHasErrors(true);
                                    setErrorMsg(res.data.message);
                                    formik.resetForm();
                                }
                                setLoading(false);
                            });
                    } else {
                        setHasErrors(true);
                        setLoading(false);
                        setErrorMsg(res.data.message);
                    }
                });
        } else {
            setStep(1);
            setHasErrors(true)
            setLoading(false)
        }
    };

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark fw-bolder mb-3'>Нууц үг мартсан</h1>
            </div>

            {hasErrors === true && (
                <div className='alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {errorMsg}
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='bg-light-success p-8 rounded'>
                    <div className='text-success'>{successMsg}</div>
                </div>
            )}

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Нэвртэх нэр</label>
                <input
                    className="form-control"
                    type="text"
                    // @ts-ignore
                    value={loginName}
                    id="example-text-input"
                    disabled
                />
            </div>
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Имэйл хаяг</label>
                <input className="form-control" type="text"
                    // @ts-ignore
                       value={encryptedEmail}
                       id="example-text-input"
                       disabled/>
            </div>
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6 required'>Нууцалсан имэйл хаяг оруулах</label>
                <input
                    type='email'
                    placeholder='Имэйл оруулна уу'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-info me-4'
                        disabled={formik.isSubmitting || !formik.isValid}>
                    {!loading && <span className='indicator-label'>Шалгах</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              Түр хүлээнэ үү....
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>
                <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-light'
                    onClick={handlePrev}
                >
                    Буцах
                </button>
            </div>
        </form>
    )
}

export {Step2}