import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState,} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {authHeader, getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import axiosWeb from "../../../../axiosWeb";

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {
  },
  currentUser: undefined,
  setCurrentUser: () => {
  },
  logout: () => {
  },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const username = currentUser?.username;
  const authorizationHeader = authHeader();
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    axiosWeb
        .post("/restapi/user/logout", {
          username: username
        }, {
          headers: {
            // @ts-ignore
            'Authorization': authorizationHeader,
          }
        })
        .then((res) => {
          if (res.data.status === 200) {
            saveAuth(undefined)
            setCurrentUser(undefined)
          }
        });

  }

  return (
      // @ts-ignore
      <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
        {children}
      </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, saveAuth} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async (api_token: string | AuthModel | undefined, username: string) => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken(api_token, username)
          if (user?.orgName) {
            // @ts-ignore
            const authSet: AuthModel = {
              // @ts-ignore
              api_token: user?.api_token,
              // @ts-ignore
              username: user?.username,
            };
            saveAuth(authSet);
            // @ts-ignore
            setCurrentUser(user)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth) {
      requestUser(auth.api_token, auth.username)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
