import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {PasswordMeterComponent} from '../../../_metronic/assets/ts/components'
import axiosWeb from '../../../axiosWeb'
import {authHeader} from '../../modules/auth/core/_requests'

const initialValues = {
  oldPassword: '',
  newPassword: '',
  changePassword: '',
}

const registrationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Хуучин нууц үг оруулна уу!'),
  newPassword: Yup.string()
    .required('Шинэ нууц үг оруулна уу!')
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]*$/,
      'Зөвхөн Англи үсэг, тоо, тусгай тэмдэгт оруулна уу!!'
    )
    .notOneOf([Yup.ref('oldPassword')], 'Хуучин нууц үгтэй ижил байж болохгүй!')
    .test('password-match', 'Нууц үг таарахгүй байна!', function (value) {
      return value !== this.parent.oldPassword
    }),
  changePassword: Yup.string()
    .required('Нууц үг давтаж оруулна уу!')
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]*$/,
      'Зөвхөн Англи үсэг, тоо, тусгай тэмдэгт оруулна уу!!'
    )
    .oneOf([Yup.ref('newPassword')], 'Нууц үг тохирохгүй байна!'),
})

export function ResetPassword() {
  const navigate = useNavigate()
  const [oldPassword, setOldPassword] = useState(false)
  const [newPassword, setNewPassword] = useState(false)
  const [changePassword, setChangePassword] = useState(false)

  const [loading, setLoading] = useState(false)
  const [responseStatus, setResponseStatus] = useState(false)
  const [successStatus, setSuccessStatus] = useState(false)
  const [sms, setSms] = useState()

  const toggleOldPasswordVis = () => {
    setOldPassword((prevShowPassword) => !prevShowPassword)
  }
  const toggleNewPasswordVis = () => {
    setNewPassword((prevShowPassword) => !prevShowPassword)
  }
  const toggleChangePasswordVis = () => {
    setChangePassword((prevShowPassword) => !prevShowPassword)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResponseStatus(false)
      setSuccessStatus(false)
    }, 3000)

    return () => clearTimeout(timeoutId)
  }, [responseStatus, successStatus])

  const {currentUser, logout} = useAuth()
  const username = currentUser?.username
  const token = currentUser?.api_token

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      handleSave(username, values.oldPassword, values.newPassword)
    },
  })

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        formik.handleSubmit()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [formik])

  const authorizationHeader = authHeader()
  const handleSave = (username: string | undefined, oldPassword: string, newPassword: string) => {
    try {
      axiosWeb
        .post(
          '/restapi/user/passwordUpdate',
          {
            username: username,
            oldPassword: oldPassword,
            newPassword: newPassword,
            token: token,
          },
          {
            headers: {
              // @ts-ignore
              Authorization: authorizationHeader,
            },
          }
        )
        .then((res) => {
          if (res.status === 401) {
            logout()
          } else {
            if (res.data.status === 200) {
              setLoading(false)
              setSuccessStatus(true)
              // @ts-ignore
              setSms('Амжилттай солисон')
              formik.resetForm()

              setTimeout(() => {
                navigate('/list')
              }, 2000)
            } else {
              setResponseStatus(true)
              setLoading(false)
              // @ts-ignore
              setSms(res.data.message)
              formik.resetForm()
            }
          }
        })
        .catch((error) => {
          logout()
          console.error('Алдаа гарлаа:', error)
          setLoading(false)
        })
    } catch (error) {
      logout()
    }
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form fv-plugins-bootstrap5 fv-plugins-framework md:px-[250px] '
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div
        className='card card-xl-stretch mb-xl'
        style={{boxShadow: '1px 2px 50px rgba(0,0,0,0.2)', borderRadius: '5px'}}
      >
        <div className='card-px card-py'>
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Нууц үг солих</h1>
          </div>

          {responseStatus && (
            <div className='alert alert-danger'>
              <div className='alert-text font-weight-bold'>{sms}</div>
            </div>
          )}
          {successStatus && (
            <div className='alert alert-success'>
              <div className='alert-text font-weight-bold'>{sms}</div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Хуучин нууц үг</label>
            <div className='input-group'>
              <input
                placeholder='Хуучин нууц үг'
                type={oldPassword ? 'text' : 'password'}
                autoComplete='off'
                {...formik.getFieldProps('oldPassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.oldPassword && formik.errors.oldPassword,
                  },
                  {
                    'is-valid': formik.touched.oldPassword && !formik.errors.oldPassword,
                  }
                )}
              />
              <span
                className='input-group-text'
                style={{cursor: 'pointer'}}
                onClick={toggleOldPasswordVis}
              >
                {oldPassword ? (
                  <i className='bi bi-eye-fill'></i>
                ) : (
                  <i className='bi bi-eye-slash-fill'></i>
                )}
              </span>
            </div>
            {formik.touched.oldPassword && formik.errors.oldPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.oldPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-8' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6 required'>Шинэ нууц үг</label>
              <div className='input-group'>
                <input
                  placeholder='Шинэ нууц үг'
                  type={newPassword ? 'text' : 'password'}
                  autoComplete='off'
                  {...formik.getFieldProps('newPassword')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                    },
                    {
                      'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                    }
                  )}
                />
                <span
                  className='input-group-text'
                  style={{cursor: 'pointer'}}
                  onClick={toggleNewPasswordVis}
                >
                  {newPassword ? (
                    <i className='bi bi-eye-fill'></i>
                  ) : (
                    <i className='bi bi-eye-slash-fill'></i>
                  )}
                </span>
              </div>
            </div>
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            ) : (
              <div className='text-muted'>
                Том үсэг, Жижиг үсэг, Тоо, Тусгай тэмдэгт агуулсан байна.
              </div>
            )}
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Нууц үг давтах</label>

            <div className='input-group'>
              <input
                placeholder='Нууц үг давтах'
                type={changePassword ? 'text' : 'password'}
                autoComplete='off'
                {...formik.getFieldProps('changePassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.changePassword && formik.errors.changePassword,
                  },
                  {
                    'is-valid': formik.touched.changePassword && !formik.errors.changePassword,
                  }
                )}
              />
              <span
                className='input-group-text'
                style={{cursor: 'pointer'}}
                onClick={toggleChangePasswordVis}
              >
                {changePassword ? (
                  <i className='bi bi-eye-fill'></i>
                ) : (
                  <i className='bi bi-eye-slash-fill'></i>
                )}
              </span>
            </div>
            {formik.touched.changePassword && formik.errors.changePassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.changePassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-primary me-2 px-6'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Солих</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Түр хүлээнэ үү...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/list'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary px-6'
              >
                Буцах
              </button>
            </Link>
          </div>
        </div>
      </div>
    </form>
  )
}
