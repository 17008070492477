/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {SearchPages} from "./SearchPages";

const DashboardPage: FC = () => (
    <div className='col-xl-12' style={{boxShadow: '1px 2px 50px rgba(0,0,0,0.2)', borderRadius: '5px'}}>
        <SearchPages className='card-xl-stretch mb-xl-8'/>
    </div>
)

const MerchantList: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage/>
        </>
    )
}

export {MerchantList}
