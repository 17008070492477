import React from "react";

export function MenuInner() {
    return (
        <>
            <div className='ms-6 ki-text-align-center row menu-item me-lg-1'>
                <p className='list-unstyled text-gray-600 fw-bold fs-6 p-0 m-0'>
                    Мерчантын хуулга авах
                </p>
            </div>
        </>
    )
}
