/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
    const intl = useIntl()

    return (
        <>
            <SidebarMenuItem
                to='/list'
                icon='element-11'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />

            <div className="menu-item">
                <a
                    className="menu-link without-sub"
                    href="https://test-resource-public.arigbank.mn/storage/document/merchant/cos_alert.pdf"
                    target="_blank"
                >
                     <span className='menu-icon'>{' '}
                         <i className="bi bi-info-square fs-3"></i>
                    </span>
                    <span className="menu-title">КҮБ анхаарах зүйлс</span>
                </a>
            </div>
            <div className="menu-item">
                <a
                    className="menu-link without-sub"
                    href="https://test-resource-public.arigbank.mn/storage/document/merchant/cos_report.pdf"
                    target="_blank"
                >
                     <span className='menu-icon'>{' '}
                         <i className="bi bi-file-earmark-spreadsheet fs-3"></i>
                    </span>
                    <span className="menu-title">Тайлан татах заавар</span>
                </a>
            </div>
            <div className="menu-item">
                <a
                    className="menu-link without-sub"
                    href="https://test-resource-public.arigbank.mn/storage/document/merchant/holboo.jpg"
                    target="_blank"
                >
                     <span className='menu-icon'>{' '}
                         <i className="bi bi-telephone-forward fs-3"></i>
                    </span>
                    <span className="menu-title">Дуудлага өгөх заавар</span>
                </a>
            </div>
        </>
    )
}

export {SidebarMenuMain}
