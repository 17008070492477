import React, {useState} from 'react';
import {Step1} from './Step1';
import {Step2} from './Step2';
import {Step3} from './Step3';
import {Step4} from './Step4';

const ForgotPasswordSteps = () => {
    const [step, setStep] = useState(1);
    const [loginName, setLoginName] = useState('');
    const [encryptedEmail, setEncryptedEmail] = useState('');
    const [hashedMail, setHashedMail] = useState('');
    const [email, setEmail] = useState('');
    const [otpCode, setOtpCode] = useState('');

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <Step1
                        setStep={setStep}
                        setLoginName={setLoginName}
                        setHashedMail={setHashedMail}
                        setEncryptedEmail={setEncryptedEmail}
                    />
                );
            case 2:
                return (
                    <Step2
                        setStep={setStep}
                        // @ts-ignore
                        loginName={loginName}
                        // @ts-ignore
                        hashedMail={hashedMail}
                        setEmail={setEmail}
                        // @ts-ignore
                        encryptedEmail={encryptedEmail}
                    />
                );
            case 3:
                return (
                    <Step3
                        // @ts-ignore
                        loginName={loginName}
                        // @ts-ignore
                        email={email}
                        // @ts-ignore
                        hashedMail={hashedMail}
                        setStep={setStep}
                        setOtpCode={setOtpCode}
                    />
                );
            case 4:
                return (
                    <Step4
                        // @ts-ignore
                        loginName={loginName}
                        // @ts-ignore
                        email={email}
                        // @ts-ignore
                        hashedMail={hashedMail}
                        // @ts-ignore
                        encryptedEmail={encryptedEmail}
                        setStep={setStep}
                        // @ts-ignore
                        otpCode={otpCode}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {renderStep()}
        </div>
    );
};

export default ForgotPasswordSteps;
