import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import React from 'react'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {config} = useLayout()
  return (
    <>
      <div className='flex items-center h-full'>
        {/* <div className={clsx('app-navbar-item flex items-center', itemClass)}>
          <span className='text-gray-700 fs-7 fw-bold pe-3 d-none d-md-block'>Өнгө солих :</span>
          <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
        </div> */}

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <img src={toAbsoluteUrl('/media/myImages/black.png')} alt='' />
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </>
  )
}

export {Navbar}
